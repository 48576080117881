import { useAxiosGet } from "./api.js";

export const useNotification = async () =>{

    const store = useNotificationStore()
    const errorStore = useErrorStore()

    const getNotifications = async()=>{
        try {
            let page = store.page
            const {data:notifications,message} = await useAxiosGet(`notification?page=${page + 1}`)
            if(notifications.notifications.length <= 0) return notifications
            if((page+1) == 1){
                store.setNotification(notifications)
            }
            else{
                store.addNotification(notifications)
            }
            return notifications
        } catch (error) {
            errorStore.showError(error.message,true,'error')
        }
    } 

    const readNotifications = async(notificationId,index)=>{
        try {
            let page = store.page
            const {data:notification,message} = await useAxiosPatch(`notification/${notificationId}`)
            store.updateNotification(notificationId,notification)
            return notification
        } catch (error) {
            errorStore.showError(error.message,true,'error')
        }
    } 

    const readAllNotifications = async() => {
        try {
            const {data:notifications,message} = await useAxiosPatch(`notification/read-all`)
            store.updateAllNotification()
            return notifications
        } catch (error) {
            errorStore.showError(error.message,true,'error')
        }
    }

    return {
        getNotifications,
        readNotifications,
        readAllNotifications
    }

}